.footer{
    background-color:#514F5E;
    color:white;
    position:fixed;
    left:0;
    bottom:0;
    width:100%;
}


